import { template as template_d2fee3aa0d864f5ab0e211fe38a6f5fe } from "@ember/template-compiler";
const FKLabel = template_d2fee3aa0d864f5ab0e211fe38a6f5fe(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
