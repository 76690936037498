import { template as template_591b4eeeec8f4cc0b6bc78ea0bdd044c } from "@ember/template-compiler";
const FKControlMenuContainer = template_591b4eeeec8f4cc0b6bc78ea0bdd044c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
