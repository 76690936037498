import { template as template_828e6dfc56df41b5ae7bfccdc2485615 } from "@ember/template-compiler";
const WelcomeHeader = template_828e6dfc56df41b5ae7bfccdc2485615(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
